<template>
  <v-row>
    <v-col cols="12">
      <h2>{{ $t("credit_management.head") }}</h2>
    </v-col>
    <v-col cols="12">
      <v-card>
        <v-card-title> {{ $t("credit_management.head_list") }} </v-card-title>
        <v-card-actions class="pb-0 d-flex align-start">
          <v-row no-gutters>
            <span>
              <date-picker-input ref="startDate" :label="$t('credit_management.date_from')" :date="startDate"
                @setValue="startDate = $event" />
            </span>
            <span>
              <date-picker-input ref="endDate" :label="$t('credit_management.date_to')" :date="endDate"
                @setValue="endDate = $event" />
            </span>
            <v-btn color="primary" class="" @click="searchDeposit"> {{ $t('search') }} </v-btn>
            <!-- <v-btn color="warning" class="" @click="preloadExportCsv">ออกรายงาน Excel</v-btn> -->
          </v-row>
        </v-card-actions>
        <v-card-actions>
          <v-spacer />
          <span class="px-3 mt-5"> {{ $t('search') }}:</span>
          <div style="width: 150px" class="mt-5">
            <v-text-field v-debounce:500ms="searchKeyword" :date="searchData" outlined dense hide-details />
          </div>
        </v-card-actions>
        <v-card-actions class="pt-0">
          <v-data-table mobile-breakpoint="0" style="width: 100%" :headers="headers" :no-data-text="$t('not_found')"
            :no-results-text="$t('not_found')" :options.sync="pagination" :server-items-length="pagination.totalItems"
            :items-per-page="pagination.rowsPerPage" :page.sync="pagination.page" :loading="loading" :footer-props="{
              'items-per-page-options': [10, 25, 30, 40, 50],
            }" :items="statement">
            <template v-slot:[`item.bank`]="{ item }">
              <span v-if="item.bank">
                <img alt="Avatar" width="25px" :src="require(`@/assets/images/logos/banks/${item.bank}.png`)" />
                <br />
                <span>{{ item.bank_account }}</span>
              </span>
              <div v-else>
                {{ item.admin_name }}
              </div>
            </template>
            <template v-slot:[`item.bank_account`]="{ item }">
              <div v-if="item.bank">{{ item.bank_account }}</div>
              <div v-else>
                {{ item.admin_name }}
              </div>
            </template>
            <template v-slot:[`item.amount`]="{ item }">
              {{ item.amount | currency }}
            </template>
            <template v-slot:[`item.bonus`]="{ item }">
              {{ item.bonus | currency }}
            </template>
            <template v-slot:[`item.total`]="{ item }">
              {{ item.total | currency }}
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <v-menu transition="slide-y-transition" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="success" dark v-bind="attrs" v-on="on">
                    <v-icon small color="white">mdi-square-edit-outline</v-icon>
                    <span class="px-3 white--text">{{ $t('credit_management.manage') }}</span>
                    <v-icon small color="white">mdi-chevron-down</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="$router.push(`/deposit/deposit_form_detail/${item.id}`)">
                    <v-list-item-title>
                      <v-icon small color="success">mdi-magnify</v-icon>
                      <span class="px-4">{{ $t('credit_management.check') }}</span>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import DatePickerInput from '@/components/input/DatePickerInput.vue'
import SelectInput from '@/components/input/SelectInput.vue'
import { getDeposit } from '@/services/apis/transaction'
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import store from '@/store'
export default {
  components: {
    DatePickerInput,
    SelectInput,
  },

  data() {
    return {
      dateFrom: moment().subtract(3, 'days').format('YY-MM-DD'),
      dateTo: moment().format('YY-MM-DD'),
      date: null,
      currentMemberDetail: {},
      search: '',
      dummy: true,
      pagination: {
        page: 1,
        rowsPerPage: 25,
        totalItems: 0,
      },
      loading: false,
      startDate: moment().subtract(3, 'days').format('YY-MM-DD'),
      endDate: moment().format('YY-MM-DD'),
      searchData: null,
      valDateStart: null,
      valDateEnd: null,
      headers: [
        {
          text: this.$t('credit_management.header1'),
          value: 'username',
          width: '100px',
          align: 'center',
          sortable: false,
        },
        // {
        //   text: 'ธนาคาร',
        //   value: 'bank',
        //   width: '100px',
        //   align: 'center',
        //   sortable: false,
        // },
        {
          text: this.$t('credit_management.header2'),
          value: 'promotion',
          width: '100px',
          align: 'center',
          sortable: false,
        },
        // {
        //   text: 'เลขบัญชี',
        //   value: 'bank_account',
        //   width: '100px',
        //   align: 'center',
        //   sortable: false,
        // },
        {
          text: this.$t('credit_management.header3'),
          value: 'date',
          width: '95px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('credit_management.header4'),
          value: 'amount',
          width: '100px',
          align: 'end',
          sortable: false,
        },
        {
          text: this.$t('credit_management.header5'),
          value: 'bonus',
          width: '100px',
          align: 'end',
          sortable: false,
        },
        {
          text: this.$t('credit_management.header6'),
          value: 'total',
          width: '100px',
          align: 'end',
          sortable: false,
        },
        {
          text: this.$t('credit_management.header7'),
          value: 'action',
          width: '100px',
          align: 'center',
          sortable: false,
        },
      ],
      statement: [{}],
      flag_interval: null,
    }
  },
  async created() {
    this.addLogPage()
    // const result = await this.getDepositData(1, 10)
    // this.statement = result.data
    this.refreshdata()
  },
  watch: {
    pagination: {
      async handler(newValue, oldValue) {
        // this.loading = true
        let cont = false
        if (oldValue.page !== newValue.page) cont = true
        if (oldValue.itemsPerPage !== newValue.itemsPerPage) cont = true
        if (oldValue.sortBy !== newValue.sortBy) cont = true
        if (oldValue.sortDesc !== newValue.sortDesc) cont = true
        // await this.getDepositData(newValue.page, newValue.itemsPerPage, this.$refs.startDate.value, this.$refs.endDate.value, this.searchData)
        if (cont && !this.dummy) await this.getDepositData(newValue.page, newValue.itemsPerPage, this.$refs.startDate.value, this.$refs.endDate.value, this.searchData)
        if (cont && this.dummy) await this.getDepositData(newValue.page, newValue.itemsPerPage, this.$refs.startDate.value, this.$refs.endDate.value, this.searchData)
      },
      deep: true,
    },
  },
  methods: {
    async addLogPage() {
      try {
        let data
        const userSystem = store.getters.getuserInfo
        await axios.get('https://api.ipify.org?format=json').then(res =>
          data = {
            ip: res.data.ip,
            name: this.$t('credit_management.report_name'),
            url: window.location.href,
            detail: this.$t('credit_management.head'),
            admin: userSystem.name ? userSystem.name : '' ? userSystem.name : '',
          }
        )
        await this.$store.dispatch('addLogPage', data)
      } catch (e) {
        // if (!e.message.includes('Unexpected token') || !e?.includes('Unexpected token') || !e.message.includes('Unexpected identifier')) {
        //   this.$swal.fire({
        //     icon: 'error',
        //     title: e.message,
        //   })
        // }
      }
    },
    async searchDeposit() {
      // let params = {
      //   time_from: this.$refs.startDate.value,
      //   time_to: this.$refs.endDate.value,
      //   ...(this.role_id ? { role_id: this.role_id } : {}),
      // }
      this.dummy = false
      await this.getDepositData(
        1,
        this.pagination.itemsPerPage,
        this.$refs.startDate.value,
        this.$refs.endDate.value,
        this.searchData
      )
      this.pagination.page = 1
    },
    async getDepositData(page, row, time_from, time_to, search) {
      this.valDateStart = this.$refs.startDate?.value
      this.valDateEnd = this.$refs.endDate?.value
      if (page !== 1 || search) {
        clearInterval(this.flag_interval)
      }
      try {
        this.loading = true
        if (this.$route.name === 'deposit') {
          this.statement = []
          const params = {
            page: page,
            rows: row,
            ...(!search ? { page: page } : {}),
            ...(!search ? { rows: row } : {}),
            // status: 'success',
            credit_to_game: 'true',
            ...(time_from ? { time_from: this.formatDateYYYY(time_from) } : {}),
            ...(time_to ? { time_to: this.formatDateYYYY(time_to) } : {}),
            ...(search ? { search: search } : {}),
          }
          let rs = await this.$store.dispatch('getDeposit', params)
          // split data
          const rsFilter = rs.data?.filter(el => el.amount >= 0)

          this.pagination.totalItems = rs.count
          this.statement = []
          rsFilter.forEach(element => {
            this.statement.push({
              id: element.id,
              amount: element.amount,
              bonus: element.bonus,

              // status: 'success',
              username: element.member ? element.member.phone : '',

              // bank: element.accountbank ? element.accountbank.bank : '',
              promotion: element.promotion ? element.promotion.name : '',
              bank_account: element.accountbank ? element.accountbank.bank_account : '',
              admin_name: element.admin ? element.admin.name : '',
              date: moment(element.updated_at).format('YY-MM-DD HH:mm'),
              total: element.amount + element.bonus,
            })
          })
        } else {
          clearInterval(this.flag_interval)
        }
        // }
      } catch (e) {
        if (!e.message.includes('Unexpected token') || !e?.includes('Unexpected token') || !e.message.includes('Unexpected identifier')) {
          this.$swal.fire({
            icon: 'error',
            title: e.message,
          })
        }
      }
      this.loading = false
    },
    async searchKeyword(value) {
      this.dummy = true;
      this.searchData = value
      await this.getDepositData(1, this.pagination.itemsPerPage, null, null, value)
      this.pagination.page = 1
    },

    async preloadExportCsv() {
      //////// get logExportData ////////
      let logExportData;
      const userSystem = store.getters.getuserInfo
      await axios.get('https://api.ipify.org?format=json').then(res =>
        logExportData = {
          ip: res.data.ip,
          exportPage: 'รายการเครดิต (ฝาก) - ระบบจัดการสมาชิก',
          url: window.location.href,
          detail: 'ระบบจัดการสมาชิก',
          admin: userSystem.name ? userSystem.name : '' ? userSystem.name : '',
        }
      )

      this.$swal({
        title: `Secure code | รายงาน${logExportData.exportPage}`,
        input: 'password',
        // inputAttributes: {
        //   inputmode: 'numeric',
        //   pattern: '[0-9]*',
        //   maxlength: 6,
        // },
        inputValidator: value => {
          if (!value) {
            return 'โปรดกรอก Secure code'
          }
          // if (value && value.length !== 6) {
          //   return 'โปรดกรอกให้ครบ 6 หลัก'
          // }
        },
        inputPlaceholder: 'ใส่รหัสที่นี่...',
        showCancelButton: true,
        confirmButtonText: 'ยืนยัน',
        cancelButtonText: 'ออก',
      }).then(async result => {
        if (result.isConfirmed) {
          const pin = result.value
          let enterSecureData = {
            ...logExportData,
            keyCheck: pin
          }
          this.loading = true
          let resEnterSecureData = await this.$store.dispatch('checkLogEnterSecure', enterSecureData)
          if(resEnterSecureData && resEnterSecureData.secure){
            this.exportCsv()
          } else {
            this.$swal.fire('ไม่สามารถ download ได้ เนื่องจากรหัส PIN ไม่ถูกต้อง', '', 'error')
          }
          this.loading = false
        }
      })
    },
    async exportCsv() {
      this.$swal.fire({
        html: this.$t('processing'),
        timerProgressBar: true,
        didOpen: () => { this.$swal.showLoading() }
      })

      let fileName = `${$t('credit_management.report_name')}_${moment(new Date()).format('YYYY-MM-DD_HH-mm')}`
      const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const fileExtension = ".xlsx";
      const wscols = [
        { wch: 15 },
        { wch: 20 },
        { wch: 20 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
      ];
      let statementlist = []
      const params = {
        page: 1,
        rows: 1000000,
        // status: 'success',
        credit_to_game: 'true',
        ...(this.$refs.startDate.value ? { time_from: this.formatDateYYYY(this.$refs.startDate.value) } : {}),
        ...(this.$refs.endDate.value ? { time_to: this.formatDateYYYY(this.$refs.endDate.value) } : {}),
        ...(this.searchData ? { search: this.searchData } : {}),
      }
      let rs = await this.$store.dispatch('getDeposit', params)
      rs.data.forEach(element => {
        statementlist.push({
          username: element.member ? String(element.member.phone) : null,
          // bank: element.bank,
          promotion: element.promotion ? String(element.promotion.name) : null,
          date: element.updated_at ? moment(element.updated_at).format('YYYY-MM-DD HH:mm') : '',
          amount: element.amount,
          bonus: element.bonus,
          total: element.bonus + element.amount,
        })
      })
      let ws = XLSX.utils.json_to_sheet(statementlist);
      ws['!cols'] = wscols;
      const wb = { Sheets: { CreditReport: ws }, SheetNames: ["CreditReport"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);

      // add log admin export
      await this.addLogExportCSV()
      this.$swal.close()
    },
    async addLogExportCSV() {
      try {
        let data
        const userSystem = store.getters.getuserInfo
        await axios.get('https://api.ipify.org?format=json').then(res =>
          data = {
            ip: res.data.ip,
            exportPage: 'รายการเครดิต (ฝาก)',
            url: window.location.href,
            detail: 'ระบบจัดการสมาชิก',
            admin: userSystem.name ? userSystem.name : '' ? userSystem.name : '',
          }
        )
        await this.$store.dispatch('addLogExportCSV', data)
      } catch (e) {
        if (!e.message.includes('Unexpected token') || !e?.includes('Unexpected token') || !e.message.includes('Unexpected identifier')) {
          this.$swal.fire({
            icon: 'error',
            title: e.message,
          })
        }
      }
    },

    refreshdata() {
      // this.$socket.$subscribe('new_deposit', data => {
      //   console.log(data)

      //   this.statement.unshift({
      //     id: data.id,
      //     amount: data.amount,
      //     bonus: data.bonus,
      //     username: data.member ? data.member.phone : '',

      //     // bank: data.accountbank ? data.accountbank.bank : '',
      //     // bank_account: data.accountbank ? data.accountbank.bank_account : '',
      //     promotion: data.promotion ? data.promotion.name : '',
      //     admin_name: data.admin ? data.admin.name : '',
      //     date: moment(data.created_at).format('YY-MM-DD HH:mm'),
      //     total: data.amount + data.bonus,
      //   })
      // })
      // this.$socket.$subscribe('update_deposit', data => {
      //   console.log(data)
      //   const index = this.statement.findIndex(obj => obj.id === data.id)
      //   this.statement[index].id = data.id
      //   this.statement[index].amount = data.amount
      //   this.statement[index].bonus = data.bonus
      //   this.statement[index].username = data.member ? data.member.phone : ''
      //   this.statement[index].bank = data.accountbank ? data.accountbank.bank : ''
      //   this.statement[index].bank_account = data.accountbank ? data.accountbank.bank_account : ''


      //   this.statement[index].admin_name = data.admin ? data.admin.name : ''
      //   this.statement[index].date = moment(data.created_at).format('YY-MM-DD HH:mm')
      //   this.statement[index].total = data.amount + data.bonus
      // })

      this.flag_interval = setInterval(() => {
        this.getDepositData(1, this.pagination.itemsPerPage, this.$refs.startDate.value, this.$refs.endDate.value)
      }, 180000)
    },
    formatDateYYYY(date) {
      const dateConv = date?.length > 8 ? date.substring(2) : date
      return moment(`20${dateConv}`).format('YYYY-MM-DD')
    },
  },
}
</script>
